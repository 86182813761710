import dispatcher from "../dispatcher";

export function changeUser(user) {
    dispatcher.dispatch({
        type: "CHANGE_USER",
        payload: user
    });
}

export function restartGame() {
    dispatcher.dispatch({
        type: "RESTART_GAME_STATUS"
    });
}
export function inituser(){
    dispatcher.dispatch(
        {type:"INIT_USER"}
    )
}
export function finishModule(status){
    dispatcher.dispatch(
        {
            type:"FINISH_MODULE",
            payload:status
        }
    )
}