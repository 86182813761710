import React,{Component} from 'react';
import "./sauvegarde.scss";
//import Helpers from "../helpers/helpers";
import UserStore from "../stores/UserStore";
import * as UserActions from "../actions/UserActions";
import {Grid, Card, CardContent, IconButton, CardActionArea, CardMedia} from  '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import helpers from '../helpers/helpers';



class Sauvegarde extends Component{
    constructor(props)
    {
        super(props);
        this.state={
            save: UserStore.getUser().saved
        }
        
    }

    deleteFromSave(i){
        let copyArray = this.state.save;
        copyArray.splice(i, 1);
        console.log(copyArray);

        UserActions.changeUser({
            saved: copyArray,
        });

        this.setState({
            save: copyArray
        })

    }

    render(){
        const { save } = this.state;
        const chapters = window.APP_DATA.chapters;
      

        return (
            <div key = {8} container spacing={3} className="save-viewer"> 
                <Grid item xs={12}>
                    <div className="save-bloc-titre">
                        <h1 >Mes contenus sauvegardés</h1>
                    </div>
                </Grid>
                {save.map((item, key) => {
                    return(
                        <Grid key={key} item xs={3} className="content-saver">
                                <Card className="save-card" onClick={()=>this.props.goToActivity(item)}>
                                    <CardMedia 
                                         className="img-save"
                                         image={`${window.PUBLIC_URL}${chapters[item.module].sequences[item.sequence].thumbnail}`}
                                         title={chapters[item.module].sequences[item.sequence].activities[item.activity].title}
                                         
                                    />
                                     <IconButton 
                                        aria-label="Save"
                                        className="save-close-btn"
                                        style={{ backgroundColor: 'transparent' }} 
                                        onClick={() => this.deleteFromSave(key)}
                                        >
                                        <CloseIcon style={{fontSize : "1em"}}/>
                                    </IconButton>
                                    <CardActionArea onClick={()=>this.props.goToActivity(item)}>
                                        <CardContent className="save-card-wrapper">
                                        <div className="save-card-content-wrapper">
                                            <h4 className="card-titre-save">{chapters[item.module].sequences[item.sequence].activities[item.activity].title!==undefined ?chapters[item.module].sequences[item.sequence].activities[item.activity].title:chapters[item.module].sequences[item.sequence].titre}</h4>
                                            <p className="p-save" dangerouslySetInnerHTML={helpers.cleanHTML(chapters[item.module].sequences[item.sequence].activities[item.activity].incipit)}/>
                                        </div>
                                        </CardContent> 
                                    </CardActionArea>
                                </Card>
                        </Grid>
                    )
                    
                })}
                <div className="baseline"></div>
            </div>
        )
    }
}
export default Sauvegarde;