import React, {useState} from 'react';
import "./basics.scss"
import {Tween, PlayState} from 'react-gsap';
import checked from '../../images/checked.svg'
import Helpers from '../../helpers/helpers.js'
import InfoIcon from '@material-ui/icons/Info';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LanguageIcon from '@material-ui/icons/Language';
import CloseIcon from '@material-ui/icons/Close';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';


const ResponsiveImage=(props)=>{
    const customStyle=(props.className!==undefined)?`responsive-image ${props.className}` :'responsive-image';
    if(props.type!=="svg"){
        return(
            <img src={props.file} className={customStyle} alt={props.content} title={props.title}/>
        )
    }else{
        return(
            <object type="image/svg+xml" data={props.file} className={customStyle}>

            </object>
        )
    }
   
}

const Vignette=(props)=>{
    const {isSelected,img,titre,obj,id,color}=props
    //console.log("vignette", props)
    return(
        <div className="menu-v" style={isSelected?{backgroundColor:color}:null} onClick={isSelected?()=>props.handleClick(id):null}>
            <div className={isSelected? "bg-v" : "bg-v v-gray" } style={{backgroundImage:`url(${img})`}}>
                {props.viewed&&<img src={checked} alt="icon-checked" className="v-checked"/>}
                <div className="label-v">
                    <h3>{titre}</h3>
                    <p>{obj}</p>
                </div>
            </div>
        </div>
    )
}
const Slider=(props)=>{
    const{min,max,value}=props
    return(
        <div className="slidecontainer">
             <input type="range" min={min} max={max} value={value} className="slider" onChange={props.onChange}/>
        </div>
    )
}

const Notification=(props)=>{
    const {text,fired}=props
    const [mounted, setMounted] = useState(!fired)
    console.log("I NOTIF -->", text, fired)

    return(
        <Tween from={{ y: '10vh', opacity:'0' }} duration={1} stagger={0.5} ease="elastic.out(0.1, 0.1)" onReverseComplete={()=>props.callBack()} playState={!mounted? PlayState.play :PlayState.reverse}>
        <div className="over-notification">
            <div className="notification">
                <div className="header">
                    <div onClick={(e)=>setMounted(true)} className="btn-close"><CloseIcon /><p>{"FERMER"}</p></div>
                </div>
            {props.image!==undefined ?
             <ResponsiveImage file={props.image} title={props.image} content="médaille de fin" className={props.className}/>
             :
             <HomeOutlinedIcon fontSize='large' style={{color:'#FFF'}}/> 

            }
           <p dangerouslySetInnerHTML={Helpers.cleanHTML(text)}/>
            </div>
        </div>
        </Tween>
    )
}

const FileBtn=(props)=>{

    const big=props.big!==undefined ? props.big :false
    const fileToOpen=Helpers.findRessource(Number(props.file)).type==="pdf"?`${window.PUBLIC_URL}/data/${Helpers.findRessource(Number(props.file)).url}`:Helpers.findRessource(Number(props.file)).url;
    console.log("FILE TO OPEN : ", fileToOpen)
    return(
        <div className={big?"item-document-big" : "item-document"} onClick={()=>window.open(fileToOpen,'file-choices')}>
           {Helpers.findRessource(Number(props.file)).type==="pdf"?
               <InsertDriveFileIcon fontSize={big?"large":"default"} className="icon-doc"/>
               :
               <LanguageIcon fontSize={big?"large":"default"} className="icon-doc"/>
           } 
        <p className={big?"lib-big":"lib"}>{Helpers.findRessource(Number(props.file)).name}</p>
    </div>
    )
}

export  {ResponsiveImage,Vignette,Slider,Notification,FileBtn}
