import React,{Component} from 'react';
import "./images.scss";
import {Grid, CardContent, CardActions, Button, IconButton} from  '@material-ui/core/';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SwipeableViews from 'react-swipeable-views';
import Helpers from "../helpers/helpers";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';



class Images extends Component {
    constructor (props){
        super(props)
         this.state={
            imageActive:0,
            activityComplete:false
        }
        this.imageBackward=this.imageBackward.bind(this);
        this.imageForward=this.imageForward.bind(this);
        this.handleStepChange=this.handleStepChange.bind(this);
    }

    imageForward(){
        const {content} = this.props.content;
        //console.log("IMAGE ACTIVE", this.state.imageActive)
        let nextImage;
        if(this.state.imageActive < content.length - 1){
            nextImage = this.state.imageActive + 1
        }else{
            nextImage = 0;
        }
        this.setState({
            imageActive: nextImage
        })
        this.getActivityComplete();
    }
    imageBackward(){
        const {content} = this.props.content;
        //console.log("IMAGE PRÉCÉDENTE")
        let nextImage;
        if(this.state.imageActive > 0){
            nextImage = this.state.imageActive-1
        }else{
            nextImage = content.length-1;
        }
        this.setState({
            imageActive: nextImage
        });
        this.getActivityComplete();
    }
    handleStepChange(step){
        this.setState({
            imageActive:step
        })
    }
    getActivityComplete(){
        const { imageActive } = this.state;
        if(this.props.content.content !== undefined){
            //console.log(imageActive, this.props.content.items.length)
            if(imageActive === this.props.content.content.length - 1){
                this.setState({
                    activityComplete: true
                })
            }
        }
    }
  
  render(){
    const { content } = this.props.content;

    return(
        <Grid container spacing={3}>
            <Grid item xs={12} className="titre-images">
                {/* <h3>{this.props.content.INCIPIT}</h3>
                <p>{this.props.content.CONSIGNE}</p> */}
            </Grid>
            <Grid item xs={12}>
                <div className="content-steps">
                    <IconButton 
                        aria-label="imgBack"
                        style={{ backgroundColor: 'transparent', color:"black" }} 
                        onClick={this.imageBackward}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <CardContent className="content-viewer">
                        <div>
                            <SwipeableViews index={this.state.imageActive} onChangeIndex={this.handleStepChange}>
                                {content.map((item, key)=>{
                                    return(
                                        <div key={key} className="step-container">
                                            <img src={`${window.PUBLIC_URL}/data/${item.src}`} alt=""/>
                                            <p dangerouslySetInnerHTML = {Helpers.cleanHTML(item.label)}></p>
                                        </div>
                                    )
                                })}
                            </SwipeableViews>
                        </div>
                        <CardActions className="steps-bloc-btn">
                            <Button disabled={!this.state.activityComplete} onClick={() => this.props.handleNext()} className='button-images'>
                                {Helpers.findLabelInData("BTN-CONTINUER")}
                                <ArrowForwardIcon className="btn-images-arrow"/>
                            </Button>
                        </CardActions>  
                    </CardContent>
                    <IconButton 
                        aria-label="imgesFwd"
                        style={{ backgroundColor: 'transparent', color:"black" }} 
                        onClick={this.imageForward}
                    >
                        <ArrowForwardIosIcon />
                    </IconButton>
                </div>
            </Grid>
        </Grid>
        )
    }
}
export default Images;

