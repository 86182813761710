import React from 'react';
import {Switch, Route} from 'react-router';
import Module from "./components/module";
import Steps from './components/steps';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.scss';

const THEME = createMuiTheme({
  typography: {
    fontFamily: [
    'Verdana',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500,
   "fontWeightBold":700
  }
});

function App() {
  return (
    <ThemeProvider theme={THEME}>
        <main className="App">
            <Switch>
                <Route exact path="/" component={Module} />
                <Route exact path="/steps" component={Steps} />
            </Switch>
        </main>
    </ThemeProvider>
  );
}

export default App;
