import React,{Component} from 'react';
import "./quizz.scss";
import {Grid, Card, CardContent, CardMedia,CardActions, Button, Radio, Slide} from  '@material-ui/core/';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import helpers from "../helpers/helpers";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';



class Quizz extends Component{
    constructor (props){
        super(props)
        this.state={
            questionEnCours: 0,
            answer: new Map(),
            questionComplete: false,
            slide: true,
            start:true
        }
        this.validateQuestion=this.validateQuestion.bind(this);
        this.changeQuestion=this.changeQuestion.bind(this);
    }

    handleClick(indice, value){
        let mapCopy = this.state.answer;
        if(this.state.answer.has(indice)){
            mapCopy.delete(indice);
        }
        else {
            mapCopy.set(indice, value);
        }
        this.setState({
            answer: mapCopy,
        }); 
    }

    changeQuestion(){
        const indice = this.state.questionEnCours+1;
        if(indice < this.props.content.questions.length){
            this.setState({
                questionEnCours: indice,
                questionComplete: false,
                answer: new Map(),
                slide: !this.state.slide,
                start:false
            });
        }else{
            this.props.handleNext();
        }
    }

    validateQuestion(){
        let result;          
        let feedback = {
            value: "",
            content: ""
        }  
        result = "true"; 
        //if only one is false, the feedback will be false
        for (let v of this.state.answer) {
            if (v[1] === "false") { 
                result = "false"; 
            }
        }
        console.log(result);
        let feedback1 = this.props.content.questions[this.state.questionEnCours].feedbacks;
        feedback1.forEach(item => {
            if(item.value === result){
                feedback.content = item.label;
                feedback.value = result;
            }
        });
        console.log(feedback);
        this.setState({
            questionComplete: true,
            questionFeedback: feedback
        })
    }

    displayQuestions(question){
        if(!this.state.slide){
            setTimeout(function(){
                this.setState({
                    slide: true
                });
            }.bind(this), 200)
        }
        return (
            <Slide 
                direction={'left'}
                in={this.state.slide}
                mountOnEnter unmountOnExit>
                <CardContent className="quizz-viewer">
                    <p className="question-bloc">{question.lib}</p>
                    <div className="bloc-items">
                        {question.items.map((item,i)=>{
                                return(
                                <div key={i} className="quizz-item" elevation={0} onClick={() => this.handleClick(i, item.value)}>
                                    {this.state.questionComplete&&this.displayIcons(i)}
                                    <Radio value={i} checked={this.state.answer.has(i)}/>  
                                    <p>{item.label}</p>
                                </div>
                                )
                            })}
                    </div>
                </CardContent>  
            </Slide>
        )
    }
    
    displayFeedBack(){
        return(
            <div className={(this.state.questionFeedback.value === "true" ? "quizz-good" : "quizz-bad") + " quizz-feedback"}>
                <p dangerouslySetInnerHTML={helpers.cleanHTML(this.state.questionFeedback.content)}></p>
            </div>
        )
    }

    displayIcons(i){
        if(this.state.answer.has(i)){
            if(this.props.content.questions[this.state.questionEnCours].items[i].value==="true")
             return <CheckCircleOutlinedIcon className="green"/>
            else
            return <HighlightOffOutlinedIcon className="red"/>
        }else{
            if(this.props.content.questions[this.state.questionEnCours].items[i].value==="true")
            return <CheckCircleOutlinedIcon  className="green"/>
        }

    }
    
    render(){
        const content = this.props.content;
        const question = this.props.content.questions[this.state.questionEnCours];
        const img=this.props.image;
        return(
                 <div className="content-wrapper">
                    <CardMedia
                        className="intro-img"
                        image={img}
                        title={content.title}
                    />
                    {this.state.questionComplete ? this.displayFeedBack() : null}
                    <Card className="content-quizz" variant={"outlined"}>
                        <div className="titre-quizz">
                            <h3>{content.title}</h3>
                            {this.state.start&&<p dangerouslySetInnerHTML={helpers.cleanHTML(content.incipit)} />}
                        </div>
                        {this.displayQuestions(question)}
                        <CardActions className="steps-bloc-btn">
                            <Button 
                            disabled={this.state.answer.size === 0 ? true : false}
                            onClick={this.state.questionComplete ? this.changeQuestion : this.validateQuestion} 
                            className='button-quizz'>
                                {this.state.questionComplete ? 
                                <>
                                    {helpers.findLabelInData("BTN-CONTINUER")}
                                    <ArrowForwardIcon className="btn-quizz-arrow"/>
                                </>
                                : 
                                    helpers.findLabelInData("BTN-VALIDER")
                                }
                            </Button>
                        </CardActions>       
                    </Card>
                </div>
        )
    }
}
export default Quizz;