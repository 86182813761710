import React,{Component} from 'react';

import './index.css';

export default class Wheel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
    this.selectItem = this.selectItem.bind(this);
    this.clean=this.clean.bind(this)
  }

  selectItem() {
    console.log("gogogo")
    if (this.state.selectedItem === null) {
      const selectedItem = this.props.itemToSelect;
      if (this.props.onSelectItem) {
       
        //this.props.onSelectItem(selectedItem);
      }
      this.setState({ selectedItem });
      setTimeout(this.clean, 3000);
    } else {
      this.setState({ selectedItem: null });
      setTimeout(this.selectItem, 500);
    }
  }
  clean(){
    console.log("clean")
    //this.setState({selectedItem:null})
    this.props.onSelectItem(this.props.itemToSelect);
  }
  render() {
    const { selectedItem } = this.state;
    const { items } = this.props;

    const wheelVars = {
      '--nb-item': items.length,
      '--selected-item': selectedItem,
    };
    const spinning = selectedItem !== null ? 'spinning' : '';

    return (
      <div className="wheel-container">
        <div className={`wheel ${spinning}`} style={wheelVars} onClick={this.props.clickBack===null?this.selectItem :null}>
          {items.map((item, index) => (
            <div className="wheel-item" key={index} style={{ '--item-nb': index }}>
              <p onClick={this.props.clickBack!==null?()=>this.props.clickBack(items[index]):null}>{item.viewed?item.lib:`SITUATION ${item.id}`}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
