import React,{Component} from 'react';
import Wheel from './wheel'
import "./sim.scss";
import helpers from "../helpers/helpers"
import {Grid, Button,Slide,CardContent,Radio} from  '@material-ui/core/';
import ReactPlayer from 'react-player'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import UserStore from "../stores/UserStore";
import * as UserActions from "../actions/UserActions";


class WheelRPS extends Component{

    constructor(props){
        super(props)
        if(UserStore.getUser().wheelState===null){
            this.state={indice:1, simComplete:false,displayChoices:false};
            this.state.items=this.prepareItems(this.props.content.content)
            this.state.start=true
            this.state.itemToSelect=0
            this.state.reviewSteps=1
            this.state.activeContent={}
            this.state.activeWheel=true
            this.state.videoEnd=false
            this.state.questionEnCours=0
            this.state.answer=new Map()
            this.state.questionComplete=false
            this.state.displayFeedBack=false
            this.state.slide=true
            this.state.wheelComplete=false
            this.state.reviewVids=false
        }
       else{
           this.state=UserStore.getUser().wheelState;
           this.state.answer=new Map();
       }
    
        this.validateQuestion=this.validateQuestion.bind(this);
        this.changeQuestion=this.changeQuestion.bind(this);
        this.callBackWheel=this.callBackWheel.bind(this)
        this.rebootQuestion=this.rebootQuestion.bind(this)
        this.launchSequence=this.launchSequence.bind(this)
        console.log("WARNING : : its a new wheelState!!!")

    }
    prepareItems(content){
        const a=[];
       //    console.log(content)
        content.forEach((item)=>{
            a.push({id:item._id,lib:item.name,viewed:false})
        })
        return a.sort(function (a, b) { return 0.5-Math.random() })
    }
    callBackWheel(selectedItem){
        console.log(selectedItem," : ",this.props.content.content," : " )
        const contToDisplay=this.props.content.content.find(item=>item._id===this.state.items[selectedItem].id)
        console.log(contToDisplay)     
        this.setState({start:false,activeContent:contToDisplay,reviewSteps:1})
      
    }
    revealAndUpdate(selectedItem){
        const a=this.state.items;
        const newItem=this.state.itemToSelect+1;
        a[selectedItem].viewed=true;
        if(this.state.reviewVids){
            this.setState({reviewVids:false},()=>{UserActions.changeUser({wheelState:this.state})})
        }else{
            if(this.state.itemToSelect<this.state.items.length-1)
                this.setState({items:a,itemToSelect:newItem,start:true,videoEnd:false},()=>{UserActions.changeUser({wheelState:this.state})})
            else{
                this.setState({items:a,wheelComplete:true,start:false},()=>{UserActions.changeUser({wheelState:this.state})})
            }

        }
        
        //UserActions.changeUser({wheelState:this.state})
    }
    handleClick(indice, value){
        let mapCopy = this.state.answer;
        if(this.state.answer.has(indice)){
            mapCopy.delete(indice);
        }
        else {
            mapCopy =new Map()
            mapCopy.set(indice, value);
        }
        this.setState({
            answer: mapCopy,
        }); 
    }

    changeQuestion(){
        const indice = this.state.questionEnCours+1;
        if(indice < this.state.activeContent.questions.length){
            this.setState({
                questionEnCours: indice,
                questionComplete: false,
                answer: new Map(),
                slide: !this.state.slide,
                displayFeedBack:false,
                videoEnd:false
            },()=>{ UserActions.changeUser({wheelState:this.state})});
        }else{
            this.handleNext();
        }
       
    }
    handleNext(){
        const newStep=this.state.reviewSteps+1
        this.setState({
            questionEnCours: 0,
            questionComplete: false,
            answer: new Map(),
            slide: !this.state.slide,
            displayFeedBack:false,
            reviewSteps:newStep,
            videoEnd:false
        },()=>{UserActions.changeUser({wheelState:this.state})});
        
    }
    validateQuestion(){
        let result;          
        let feedback = {
        }  
        console.log(this.state.answer)
        for (let v of this.state.answer) {
           
            result = v[1]; 
        }
        //if only one is false, the feedback will be false
       
        console.log(this.state.answer," ",result);
        let feedback1 = this.state.activeContent.questions[this.state.questionEnCours].feedbacks;
        feedback=feedback1.find(f=>f.id===result.id)
        console.log(feedback);
        const isComplete=result.value==="true"?true :false
        this.setState({
            questionComplete: isComplete,
            questionFeedback: feedback,
            displayFeedBack:true
        },()=>{UserActions.changeUser({wheelState:this.state})})
    }
    rebootQuestion(){
        this.setState(
            {
                answer:new Map(),
                displayFeedBack:false
            }
        )
    }
    displayIcons(i){
        if(this.state.answer.has(i)){
            if(this.state.activeContent.questions[this.state.questionEnCours].items[i].value==="true")
             return <CheckCircleOutlinedIcon className="green"/>
            else
            return <HighlightOffOutlinedIcon className="red"/>
        }else{
            if(this.state.activeContent.questions[this.state.questionEnCours].items[i].value==="true")
            return <CheckCircleOutlinedIcon  className="green"/>
        }

    }
    displayQuestions(question){
        if(!this.state.slide){
            setTimeout(function(){
                this.setState({
                    slide: true
                });
            }.bind(this), 200)
        }
        return (
            <Slide 
                direction={'left'}
                in={this.state.slide}
                mountOnEnter unmountOnExit>
                <CardContent className="quizz-viewer">
                    <p className="question-bloc">{question.question}</p>
                    <div className="bloc-items">
                        {question.items.map((item,i)=>{
                                return(
                                <div key={i} className="quizz-item" elevation={0} onClick={() => this.handleClick(i, item)}>
                                    {this.state.questionComplete&&this.displayIcons(i)}
                                    <Radio value={i} checked={this.state.answer.has(i)}/>  
                                    <p>{item.lib}</p>
                                </div>
                                )
                            })}
                    </div>
                    {this.state.displayFeedBack&&
                        this.displayFeedBack()
                    }
                    <div className="steps-bloc-btn">
                            <Button 
                            disabled={this.state.answer.size === 0 ? this.state.questionComplete ? false : true : false}
                            onClick={this.state.questionComplete ? this.changeQuestion : this.state.displayFeedBack ? this.rebootQuestion :this.validateQuestion} 
                            className='button-quizz'>
                                {this.state.questionComplete ? 
                                <>
                                    {helpers.findLabelInData("BTN-CONTINUER")}
                                    <ArrowForwardIcon className="btn-quizz-arrow"/>
                                </>
                                : 
                                    this.state.displayFeedBack ?
                                    "RECOMMENCER"
                                    :
                                    helpers.findLabelInData("BTN-VALIDER")
                                }
                            </Button>
                        </div>   
                </CardContent>  
            </Slide>
        )
    }
    
    displayFeedBack(){
        return(
            <div className={(this.state.questionComplete ? "quizz-good" : "quizz-bad") + " quizz-feedback"}>
                <p dangerouslySetInnerHTML={helpers.cleanHTML(this.state.questionFeedback.lib)}></p>
            </div>
        )
    }
    displayvideo(video){
        const nextStep=this.state.reviewSteps+1;
        const videofile=`${window.PUBLIC_URL}${video}`
        return(
            <div className="video-wrapper-3">
                <ReactPlayer
                    playing
                    url={videofile}
                    controls={true}
                    width="100%"
                    height="100%"
                    onEnded={()=>this.setState({videoEnd:true},()=>{UserActions.changeUser({wheelState:this.state})})}
                    className="video-player"
                />
                {
                    this.state.videoEnd&&
                    <div className="video-bloc-btn">
                        <Button 
                            onClick={()=>this.setState({reviewSteps:nextStep},()=>{UserActions.changeUser({wheelState:this.state})})}                                
                            className='button-intro'
                        >         
                            {"Décodez ce que vous venez de voir !"}
                        </Button>
                    </div>  
                }       
            </div>
        )
    }
    displayvideoGood(video){
        const nextStep=this.state.reviewSteps+1;
        const videofile=`${window.PUBLIC_URL}${video}`
        return(
            <div className="video-wrapper-3">
                <ReactPlayer
                    playing
                    url={videofile}
                    controls={true}
                    width="100%"
                    height="100%"
                    onEnded={()=>this.setState({videoEnd:true})}
                    className="video-player"
                />
                {
                    this.state.videoEnd&&
                    <div className="video-bloc-btn">
                        <Button 
                            onClick={()=>this.revealAndUpdate(this.state.itemToSelect)}                                
                            className='button-intro'
                            style={{ backgroundColor: 'transparent' }}>
                                 {"Continuez votre exploration  !"}
                        </Button>
                    </div>  
                }       
            </div>
        )
    }
    displayStep(){
        const {reviewSteps,activeContent,videoEnd,questionEnCours}=this.state
    
        if(this.state.wheelComplete&&!this.state.reviewVids){
            UserActions.changeUser({wheelState:this.state})
            return(
                <>
                    <div className="end-msg" dangerouslySetInnerHTML={helpers.cleanHTML(this.props.content.fin)}/>
                    <div className="end-bloc-btn">
                        <Button 
                            onClick={this.props.handleNext}                                
                            className='button-intro'
                            style={{ backgroundColor: 'transparent' }}>
                                Continuer
                                <ArrowForwardIcon className="arrow-btn-conclusion"/>
                        </Button>
                    </div>  
                </> 
            )
        }else
        {
            switch(reviewSteps){
                case 1 :
                   return (
                       <div className="video-bad">
                            <h3>Regardez attentivement cette situation !</h3>
                            {this.displayvideo(activeContent.video_bad)}
                       </div>
                       
                    )
                    
                case 2 :
                    return this.displayQuestions(activeContent.questions[questionEnCours]);
                case 3 :
                    return(
                        <div className="video-bad">
                            <h3>Découvrez maintenant la bonne pratique !</h3>
                            {this.displayvideoGood(activeContent.video_good)}
                       </div>
                    )
                default :
            }
        }
       
    }
    launchSequence(sItem){
        console.log("items !!!",sItem)
        console.log(sItem.id," : ",this.props.content.content," : " )
        const contToDisplay=this.props.content.content.find(item=>item._id===sItem.id)
        console.log(contToDisplay)     
        this.setState({start:false,activeContent:contToDisplay,reviewSteps:1,reviewVids:true},()=>{UserActions.changeUser({wheelState:this.state})})
      //this.setState({start:false,activeContent:item,reviewSteps:1})
    }
    render(){
        console.log("RENDER")
        const content = this.props.content;
        console.log(this.state.items)
        return(
            <div className="act-container">
                <div className="wheel-interactive">
                     <Wheel items={this.state.items} onSelectItem={this.callBackWheel} itemToSelect={this.state.itemToSelect} clickBack={this.state.wheelComplete?this.launchSequence:null}/>
                </div>
                <div className="video-content-container">
                    {this.state.start?
                        <>
                            <h3>{content.consigne}</h3>
                        </> 
                        :
                        this.displayStep()
                    }
                </div>
            </div>
        )
        
    }

}
export default WheelRPS;