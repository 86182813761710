import React,{Component} from 'react';
import "./cases.scss";
import {Grid, Paper, CardContent, CardActions, Button, Radio, CardMedia, Slide, Fade} from  '@material-ui/core/';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Helpers from "../helpers/helpers";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Image from "react-image-enlarger";

class Cases extends Component{
    constructor (props){
        super(props)
        this.state={
            questionEnCours: 0,
            answer: new Map(),
            questionComplete: false,
            imageFade: true,
            feedbackSlide: false, 
            slide: true
        }
        this.validateQuestion=this.validateQuestion.bind(this);
        this.changeQuestion=this.changeQuestion.bind(this);
    }
    setZoomed(bool){
        this.setState({
            zoomed: bool,
        })
    }
    handleClick(indice, action){
        let mapCopy = this.state.answer;
        if(this.state.answer.has(indice)){
            mapCopy.delete(indice);
        }
        else {
            mapCopy = new Map();
            mapCopy.set(indice, action);
        }
        this.setState({
            answer: mapCopy,
        }); 
    }

    changeQuestion(){
        this.setState({
            imageFade: false,
            slide: false
        })
        const indice = this.state.questionEnCours + 1;
        if(indice < this.props.content.questions.length){
            setTimeout(function(){
                this.setState({
                    questionEnCours: indice,
                    questionComplete: false,
                    answer: new Map(),
                    imageFade: true,
                    slide: true
                });
            }.bind(this), 1000)
        }else{
            this.props.handleNext();
        }
    }

    validateQuestion(){
        let result;         
        //if only one is false, the feedback will be false
        for (let v of this.state.answer) {
            result = v; 
        }
        this.setState({
            questionComplete: true,
            questionFeedback: result
        })
    }

    slideFeedback(){
        this.setState({
            x : !this.state.x
        })
    }

    displayFeedBack(){
        let feedback = this.props.content.questions[this.state.questionEnCours].feedbacks[0];
        let rep = this.props.content.questions[this.state.questionEnCours].items[this.state.questionFeedback[0]];
        const border = this.state.questionFeedback[1] === "true" ? "borderGood" : "borderWrong";
        const text = rep.content.length <= 5 ? "short-answer-feedback": '';
        let strFeedback = JSON.stringify(feedback.content).length;
        const feedbackLarge = strFeedback < 500 ? "" : "long-feedback";
        return(
            <>
            <div>
                <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={1500}>
                    <Paper className={`cases-answer ${border} ${text} ${feedbackLarge}`}>
                        {(this.state.questionFeedback[1] === "true") ? 
                        (<h2 className="icon-feedback"><CheckCircleOutlinedIcon className="green icon" /></h2>) : 
                        (<h2 className="icon-feedback"><HighlightOffOutlinedIcon className="red icon"/></h2>)}
                        <p>{rep.content}</p>
                    </Paper>
                </Slide>
            </div>
                {strFeedback < 500 ? 
                    <Fade in={true} timeout={3000}>
                        <p dangerouslySetInnerHTML={Helpers.cleanHTML(feedback.content)} className="cases-feedback"></p>
                    </Fade>
                    :
                    <>
                        <CardActions className="feedback-large-btn">
                            <Button 
                                onClick={() => this.slideFeedback()} 
                                className='button-steps'>EXPLICATION {'->'}
                            </Button>
                        </CardActions>    
                        <Slide direction="left" in={this.state.x} mountOnEnter unmountOnExit timeout={1000}>
                            <CardContent className="cases-viewer">
                                <p dangerouslySetInnerHTML={Helpers.cleanHTML(feedback.content)} className="large-feedback"></p>
                            </CardContent>
                        </Slide>  
                    </>                 
                }
            </>
        )
    }
    
    render(){
        const content = this.props.content;
        const question = this.props.content.questions[this.state.questionEnCours];
        const img1=`${window.PUBLIC_URL}${content.image}`;
        return(
            <Grid container spacing={1}>
                 <Grid item xs={12}>
                    <div className="titre-cases">
                        <p>{content.incipit}</p>
                    </div>
                 </Grid>
                 <Grid item xs={7}>
                     <div className="cases-img-bloc">
                        <Fade in={this.state.imageFade} timeout={1000}>
                            <CardMedia 
                                className="cases-img" image={img1} titre="blabla">
                            </CardMedia>      
                       </Fade>  
                     </div>
                 </Grid>
                 <Grid item xs={5}>
                    <div className="content-cases">
                        <Slide 
                            direction={'left'}
                            in={this.state.slide}
                            mountOnEnter unmountOnExit>
                            <CardContent className="cases-viewer">
                                <Paper elevation={0} className="cases-comment">
                                    <div dangerouslySetInnerHTML={Helpers.cleanHTML(question.lib)}/>
                                </Paper>
                                {this.state.questionComplete ? this.displayFeedBack() :                          
                                    question.items.map((item,i)=>{
                                        return(
                                        <Paper 
                                            key={i} 
                                            className={(item.content.length < 5 ? "short-text" : "") + " cases-item"} 
                                            elevation={0} 
                                            onClick={() => this.handleClick(i, item.action)}>
                                            <Radio value={i} checked={this.state.answer.has(i)}/> 
                                            <p>{item.content}</p>
                                        </Paper>
                                        )
                                    })
                                }
                                {this.state.slide ? 
                                    <CardActions className="cases-bloc-btn">
                                        <Button 
                                            disabled={this.state.answer.size === 0 ? true : false} 
                                            onClick={this.state.questionComplete ? this.changeQuestion : this.validateQuestion} 
                                            className='button-cases'>
                                            {this.state.questionComplete ? 
                                                <>
                                                    {Helpers.findLabelInData("BTN-CONTINUER")}
                                                    <ArrowForwardIcon className="btn-cases-arrow"/>
                                                </>
                                                : 
                                                    Helpers.findLabelInData("BTN-VALIDER")
                                            }
                                        </Button>
                                    </CardActions>
                                    :
                                    null
                                }
                            </CardContent>
                            
                        </Slide>  
                    </div>
                 </Grid>
            </Grid>
        )
    }
}
export default Cases;