//import GameStatusStore from "../stores/GameStatusStore";
import dompurify from 'dompurify';
class Helpers {
    _setDateWeekday(date, day) {
        var currentDay = date.getDay();
        var distance = day - currentDay;
        date.setDate(date.getDate() + distance);
        return date;
    }

    getChapterDate(chapter) {
        let date = new Date();
        date = this._setDateWeekday(date, chapter.weekday);
        date.setHours(chapter.hour);
        date.setMinutes(chapter.minutes || 0);

        return date;
    }

    sumObjectsByKey(...objs) {
        return objs.reduce((a, b) => {
            for (let k in b) {
                if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
            }
            return a;
        }, {});
    }

    findChapterById(id) {
        let chapters = window.APP_DATA.chapters.filter(ch => ch._id === id);
        return chapters[0];
    }

    /*findCheckpointById(id, chapter = null) {
        if (!chapter) {
            chapter = GameStatusStore.gameStatus.currentChapter;
        }
        let checkpoints = chapter.checkpoints.filter(pt => pt._id === id);
        return checkpoints[0];
    }*/

    uniq(a) {
        return [...new Set(a.map(o => JSON.stringify(o)))].map(s =>
            JSON.parse(s)
        );
    }

    DEBUG(a, b, ...args) {
        console.log(
            "debug:%c" + a + " %c" + b,
            "font-weight: bold; color: #eb5a81;",
            "color: DodgerBlue;",
            ...args
        );
    }
    cleanHTML(pContent){
        const sanitize=dompurify.sanitize;
        return {__html:sanitize(pContent)}
    }
    findLabelInData(pKey){
        const r = window.APP_DATA.contents.find(item=> item.key === pKey)
        return (
            (r !== undefined ) ? r.label : "NOT FOUND"
        )

    }
}

const helpers = new Helpers();
export default helpers;
