import React,{Component} from 'react';
import "./module.scss"
import helpers from "../helpers/helpers"
import UserStore from "../stores/UserStore";
import * as UserActions from "../actions/UserActions"
import Carousel from 'react-material-ui-carousel'
import {Container, Grid, Card, CardContent, Button, LinearProgress, Slide, Typography} from  '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import Steps from "./steps";
import TimerIcon from '@material-ui/icons/Timer';
import AssistantIcon from '@material-ui/icons/Assistant';
import MapIcon from '@material-ui/icons/Map';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {Notification} from "./units/basics"
import badge from "../images/medaille.png" 

const CustomLinear = withStyles({
    root: {
      backgroundColor: '#FFFFFF',
      borderColor:"#ec6e51",
    },
    bar: {
      backgroundColor: '#ec6e51',
    },
  })(LinearProgress);


class Module extends Component{
    constructor()
    {
        super();
        this.wrapper = React.createRef();
        this.state={
            user: UserStore.getUser(),
            viewMenu: true,
            moduleIn: true,
            moduleStarted: 0,
            moduleEnCours: "",
            sequenceEnCours: 0,
            quizzFinal: false,
            displayNotif:true,
            slide: false,
        }
        this.goBackToModule = this.goBackToModule.bind(this);

        console.log(this.state.user);
    }
    handleWindowClose(e){
        e.preventDefault();
        UserActions.finishModule(this.state.user.moduleFinished)
        window.SCORM_HANDLER.windowUnloadHandler();
        console.log("close module")
    }
    // checkUser() {
    //     // go back to intro if there is no user
    //     let { user } = this.state;
    //     if (user.lng === "" ) {
    //         //UserActions.restartGame();
    //         return <Redirect to="/" />;
    //     }
    // }
    checkProgressComplete(){
        const {gamePath}=this.state.user
        let globalProgress=0
        gamePath.forEach(module=>{
            globalProgress+=module.progress
        })
        console.log("PROGRES TOTAL : ",globalProgress/gamePath.length);
        UserActions.changeUser({globalProgress:Math.round(globalProgress/gamePath.length)});
        if(Math.round(globalProgress/gamePath.length)>95)
             UserActions.changeUser({moduleFinished:true});
        return Math.round(globalProgress/gamePath.length)
    }
    finishModule(){
        UserActions.changeUser({moduleFinished:true})
        UserActions.finishModule(true);
        this.setState({displayNotif:false});
    }
    startModule(event, module){
        // Démarre le module
        let array = window.APP_DATA.chapters;
        let currentSocleIndex = array.findIndex(i => i._id === module);

        this.setState({
            moduleIn: false
        });
        setTimeout(function(){
            this.setState({
                socleEnCours: module,
                slide: true,
                viewMenu: false,
                moduleStarted: currentSocleIndex
            });
        }.bind(this), 300);

        console.log(UserStore.getUser().gamePath);

        console.log("Module ", module, "démarré");
    }

    goBackToModule(){
        //Retour au Menu/Dashboard
        this.setState({
            slide: false,
            moduleIn: true,
        });
        setTimeout(function(){
            this.setState({
                viewMenu: true, 
                slide: true
            });
        }.bind(this), 150);

    }

    moduleIcon(i){
        switch(i){
            case 0 :
                return (
                    <EditLocationIcon className="" style={{fontSize : "2em", color: "#34398d"}}/>
                )
            case 1 :
                return (
                    <MapIcon className="" style={{fontSize : "2em", color: "#34398d"}}/>
                )
            case 2 :
                return(
                    <AssistantIcon className="" style={{fontSize : "2em", color: "#34398d"}}/>
                )
            default:
                return null
        }
    }


    displayCaroussel(data){
        const imgIntro=`${window.PUBLIC_URL}/data/assets/img-1.png`;
        const textClass = [null, "mid-intro", "bloc-intro"]
        return(
           <Carousel animation = "slide" autoPlay={true} interval={15000}>
                {
                    data.masthead.map( item => 
                        <Card key={`${window.PUBLIC_URL}${item.picture}`} className="module-intro" variant="outlined">
                            <img src={`${window.PUBLIC_URL}${item.picture}`} alt="Module" className="img-intro"/>
                            <CardContent className={textClass[item.slide]}>
                                <div dangerouslySetInnerHTML={helpers.cleanHTML(item.content)} className="content-masthead"></div>
                            </CardContent>
                        </Card>
                    )
                }
            </Carousel> 
        )
    }

    displayModuleCards(item, key){
        let progress = this.state.user.gamePath;
        console.log(item)
        return(
                <Card className={"socle-card"} variant="outlined" key={key}>
                    <CardContent className="socle-card-wrapper">
                        <div className="socle-card-content-wrapper">
                            {/*this.moduleIcon(key)*/}
                            <h4 className="card-titre-socle" dangerouslySetInnerHTML={helpers.cleanHTML(helpers.findChapterById(item.module).titre)}/>
                            <div className="timerIcon">
                                <TimerIcon className="" style={{fontSize : "0.8em", marginRight: "0.2rem"}}/>
                                <span>{helpers.findChapterById(item.module).time}</span>
                            </div>
                            <p className="p-socle">{helpers.findChapterById(item.module).abstract}</p>
                        </div>
                    </CardContent>
                    <div className="progress-bloc">
                        <CustomLinear className="moduleProgress" variant="determinate"
                        value={progress[key].progress}
                        ></CustomLinear>    
                        <Typography variant="body2" color="textSecondary" className="progress-text">
                            {progress[key].progress}%
                        </Typography>
                    </div>   
                    <Button 
                        onClick={(event)=>this.startModule(event, item.module)}
                        className='button-socle'
                        style={{ backgroundColor: 'transparent' }}
                        >
                            {helpers.findLabelInData("BTN-HOME")}
                    </Button>
                </Card>
        )
    }

    displayMenuSequence(){
        if(!this.state.slide){
            setTimeout(function(){
                this.setState({
                    slide: true
                });
            }.bind(this), 300);
        }
        else{
            return (
                <Steps content={this.state.moduleStarted} backHandler={this.goBackToModule} continueHandler={this.handle_Continue}/>
            )
        }
    }

    displayMenuModule(){
        const header = window.APP_DATA.header;
        return(
            <Slide direction={'up'}
                in={this.state.moduleIn}
                mountOnEnter unmountOnExit>
                <Grid container spacing={0} className="module-viewer" classes={{root:"width=100%"}}> 
                    <Grid item xs={12}>
                        <div className="module-titre">
                            <div className="titre-content">
                                <h1 className="titre">{header.name}</h1>
                                <p className="sous-titre">{header.title}</p>
                            </div>   
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{paddingBottom: "0",marginBottom:"0px"}} spacing={0}>
                        {this.displayCaroussel(header)}
                    </Grid>
                   
                    <Grid item xs={12} md={11} lg={11} className="grid-module">
                        {this.state.user.gamePath!==undefined ? this.state.user.gamePath.map((item, key)=> {return (this.displayModuleCards(item, key))}):null}
                    </Grid>
                </Grid>
            </Slide>
        )
    }
    render(){
        const {viewMenu} = this.state;
        const text=`<p>Bravo ! Vous Vous avez complété <b>l'intégralité du module !</b> <p>Vous pouvez desormais le quitter en fermant la fenêtre de votre navigateur.</p>`
        return(
                <Container className="viewer" disableGutters={false} maxWidth={false}>
                    {(this.checkProgressComplete()===100&&this.state.displayNotif)&&
                        <Notification text={text} fired={this.state.displayNotif} callBack={()=>this.finishModule()} image={badge} className="medaille"/>
                     }
                   {viewMenu ? this.displayMenuModule() : this.displayMenuSequence()}
                </Container>
           
        )
    }
    
}
export default Module;
