import React,{Component} from 'react';
import "./sim.scss";
import helpers from "../helpers/helpers"
import {Grid, Button} from  '@material-ui/core/';
import ReactPlayer from 'react-player'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';


class Sim extends Component{

    constructor(props){
        super(props)
        this.state={indice:1, simComplete:false,displayChoices:false};
    }
    findSequence(pIndice){
        return this.props.content.content.find(item=> item._id === pIndice)
    }
    checkVideo(){
        const seq=this.findSequence(this.state.indice)
        if(seq.auto){
            if(seq.goto==="end"){
                this.setState({simComplete:true})
            }else
            this.setState({indice:seq.goto})
        }else{
            this.setState({displayChoices:true})
        }
    }
    changeSeq(pindice){
        this.setState({displayChoices:false,indice:pindice})
    }
    displayChoices(){
        const seqEnCours=this.findSequence(this.state.indice)
        return(
            <div className="choices-videos">
                {
                    seqEnCours.choices.map((item,i)=>{
                        
                        return(
                            <Button 
                            key={i}
                            onClick={()=>this.changeSeq(item.goto)}                                
                            className={`button-choice`}
                            style={{ backgroundColor: 'transparent' }}>
                                {item.action}
                            </Button>
                        )
                    })
                }
            </div>
        )
    }
    render(){
        const video=`${window.PUBLIC_URL}${this.findSequence(this.state.indice).file}`;
        console.log(this.props.content.content, this.findSequence(this.state.indice).file);
        const content = this.props.content;
        return(
            <Grid container spacing={1}>
                 <Grid item xs={12}>
                    <div className="titre-video">
                        <p>{content.incipit}</p>
                    </div>
                 </Grid>
                 <div className="video-container">
                    <div className="video-wrapper-2">
                        <ReactPlayer
                            playing
                            url={video}
                            controls={false}
                            width="100%"
                            height="100%"
                            onEnded={()=>this.checkVideo()}
                            className="video-player"
                        />
                        {this.state.displayChoices?this.displayChoices():null}
                    </div>
                    <div className="video-bloc-btn">
                        <Button 
                            onClick={this.props.handleNext}                                
                            className={`button-video ${!this.state.simComplete ? 'disabled': null}`}
                            style={{ backgroundColor: 'transparent' }}>
                                {helpers.findLabelInData("BTN-CONTINUER")}
                                <ArrowForwardIcon className="btn-video-arrow"/>
                        </Button>
                    </div>  
                 </div>
            </Grid>
        )
        
    }

}
export default Sim;