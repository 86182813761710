import { EventEmitter } from "events";

import dispatcher from "../dispatcher";

class UserStore extends EventEmitter {
    constructor() {
        super();
        let suspend = window.SCORM_HANDLER.getSuspendData();
        if (suspend && suspend.user) {
            this.user = suspend.user;
        } else {
            this.user = {
                _id: "",
                firstname: "",
                lastname: "",
                globalProgress: 0,
                gamePath:[],
                moduleFinished:false,
                score:30,
                saved: [],
                wheelState:null
            };
            this.initGame();
        }
    }

    initGame(){
        console.log("init game !!!",window.APP_DATA.chapters )
        let gameModule = window.APP_DATA.chapters.length;
        for(let i=0; i < gameModule; i++){
            this.user.gamePath.push({
                module: window.APP_DATA.chapters[i]._id,
                progress: 0,
                sequence: this.fillSequences(window.APP_DATA.chapters[i]._id)
            })
        }
    }
    fillSequences(pChapterId){
        const sequences=window.APP_DATA.chapters.find(chapter=>chapter._id===pChapterId).sequences
        const aS=[];
        sequences.forEach(sequence => {
            aS.push({id:sequence._id,completed:false})
        });
        return aS
    }
    getUser() {
        console.log("init user !!! ",this.user)
        return this.user;
    }

    setUser(user) {
        this.user = {...this.user,...user};
        window.SCORM_HANDLER.setSuspendData(null, this.user);
        const scormStatus=this.user.moduleFinished ? "completed" : "incomplete";
        const scormSuccess=this.user.score>0 ?this.user.score>=80?"passed" : "failed" :"unknown"
        this.user = {...this.user,...user};
        window.SCORM_HANDLER.setLessonStatus(scormStatus);
        window.SCORM_HANDLER.setSucessStatus(scormSuccess);
        window.SCORM_HANDLER.setSuspendData(null, this.user);
        this.emit("change");
    }
    restart() {
        window.SCORM_HANDLER.setLessonStatus("incomplete");
        this.initGame();
        this.emit("change");
    }
    finalizeModule(status){
        if(status){
            this.user.moduleFinished=true; 
            this.user.score=100
        }else{
            this.user.moduleFinished=false
            this.user.score=60
        }
            
        this.setUser(this.user) ;

    }
    handleActions(action) {
        switch (action.type) {
            case "CHANGE_USER":
                this.setUser(action.payload);
                break;
            case "RESTART_GAME_STATUS":
                this.restart();
            break;
            case "FINISH_MODULE" :
                this.finalizeModule(action.payload);
                break;
            default:
                break;
        }
    }
}

const userStore = new UserStore();
dispatcher.register(userStore.handleActions.bind(userStore));

export default userStore;
