import React, {useState} from 'react';
import "./navbar.scss"
import {IconButton} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
//import { Redirect } from "react-router-dom";


const NavBar = ({goHome, goSave}) => {
    const header = window.APP_DATA.header;
    const [home,setHome]=useState(false)
    const [save,setSave]=useState(false)    
    return(
        <div className='nav'>  
            <div className="module-titre">
	      {/*                <div className="bloc-marque"> 
                    
                </div>   */}
                {/* <div className="titre-content">
                    <h1 className="titre">{header.name}</h1>
                    <p className="sous-titre">{header.title}</p>
                </div> */}
            </div>
            <div className="menu-icons">
                {home&&<div className="tooltip"><p>Vos contenus sauvegardés</p></div>}
                <IconButton 
                    aria-label="Save"
                    style={{ backgroundColor: '#F2F2F2' }} 
                    onMouseEnter={(e)=>setHome(true)}
                    onMouseLeave={(e)=>setHome(false)}
                    onClick={() => goSave()}
                    className={"icon"}
                    >
                    <BookmarkBorderIcon style={{fontSize : "1em", color: "#cb531b"}}/>
                </IconButton>
                {save&&<div className="tooltip-2"><p>Revenir à l'accueil</p></div>}
                <IconButton 
                    aria-label="Menu"
                    style={{ backgroundColor: '#F2F2F2' }} 
                    onClick={() => goHome()}
                    onMouseEnter={(e)=>setSave(true)}
                    onMouseLeave={(e)=>setSave(false)}
                    className={"save-icon"}
                    >
                    <HomeIcon className="home-icon" style={{fontSize : "1em"}}/>
                </IconButton>
            </div>
        </div>
    )

}

export default NavBar;




